.privacy-policy {
  display: flex;
  align-items: center;

  color: #818181;
}

.privacy-policy .content {
  padding: 20px 30px;
  font-size: 1rem;
  max-width: 800px;
}

.privacy-policy .content h1, .privacy-policy .content h2 {
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 10px;
}

.privacy-policy .content h1 {
  font-size: 30px;
}

.privacy-policy .content h2 {
  font-size: 24px;
    margin-top: 2rem;
}

.privacy-policy .content p {
  width: 100%;
  text-align: justify;
}

.privacy-policy .content p + p {
  margin-top: 1rem;
}
