@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500&display=swap");

* {
  margin: 0px;
  padding: 0px;
  outline: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.8;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px;
  width: 100%;
}

.content h2 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

.btn-default {
  width: 200px;
  margin: 10px 0px;
  padding: 10px;

  background-color: #22698d;
  border: none;
  display: block;
  width: 100%;

  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
}

img.icone-libras {
  height: 30px;
  width: 30px;
  margin-left: 10px;
  vertical-align: middle;
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
